<template>
  <div>
    <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
      <div class="form-row">
        <div class="col-md-8">
          <b-card class="mb-1" header="Informações básicas">
            <b-card-text>
              <div class="form-row">
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>
                    <b-form-input
                      v-model="item.title"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                    />
                  </b-form-group>
                </b-col>
             
                <b-col md="12">
                  <b-form-group class="mt-1">
                    <label for="">
                      Descrição
                    </label>
                    <editor
                      class="description-editor-1"
                      api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                      v-model="item.description"
                      :init="this.optionEditor({
                        height: 600,
                        selector: 'description-editor-1',
                      })"
                    />
                  </b-form-group>
                </b-col>
              
                <b-col md="12">
                  <b-form-group class="mt-1 mb-0">
                    <label for="">
                      Sobre o produto - <small>Será exibido no final da págna.</small>
                    </label>
                    <editor
                      class="about-editor-2"
                      api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                      v-model="item.about"
                      :init="this.optionEditor({
                        height: 300,
                        selector: 'about-editor-2',
                      })"
                    />
                  </b-form-group>
                </b-col>
              </div>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Sites de buscas">
            <b-card-text>
              <p class="mb-3">
                Caso queira personalizar o título, descrição e url para
                mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar
                SEO do site" e preencha os campos abaixo.
              </p>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Título SEO</label>
                    <input
                      v-model="item.title_seo"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">URL</label>
                    <b-input-group
                      :prepend="`/cursos/online/`"
                      class="input-group-merge"
                    >
                      <b-form-input
                        class="text-primary pl-1"
                        v-model="item.slug"
                      />
                    </b-input-group>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Descrição para SEO</label>
                    <textarea
                      v-model="item.description_seo"
                      maxlength="320"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card header="Imagem de destaque" class="mb-1">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    class="form-control-file hidden"
                    @change="previewImage"
                  />
                  <b-form-group>
                    <label
                      for="image"
                      class="d-block text-center"
                      style="
                        border: 1px solid rgb(222 222 222);
                        border-radius: 4px;
                        border-style: dashed;
                        cursor: pointer;
                        font-size: 1.1rem;
                      "
                    >
                      <div v-if="!preview && !image_url" class="pt-5 py-4">
                        <feather-icon size="19" icon="ImageIcon" />
                        Selecionar imagem
                      </div>
                      <template v-if="preview">
                        <b-img
                          for="image"
                          :src="preview"
                          style="width: 100%;"
                          fluid
                        />
                      </template>
                      <template v-else-if="!preview && image_url">
                        <b-img
                          for="image"
                          :src="image_url"
                          style="width: 100%;"
                          fluid
                        />
                      </template>
                    </label>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Vídeo de apresentação</label>
                    <b-form-input
                      type="text"
                      placeholder="Link do YouTube/Vimeo"
                      v-model="item.video_url"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Label da imagem
                  </label>
                  <b-form-group class="mb-0">
                    <b-form-input
                      :class="{ 'is-invalid': $v.item.label_image.$error }"
                      v-model="item.label_image"
                      type="text"
                    />
                  </b-form-group>
                  <small class="d-block" style="margin-top: 4px">
                    Será exibido sobre a imagem de destaque.
                  </small>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="API Questões">
            <b-card-text>
              <div class="row">
                <b-col md="12">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Área
                    </label>
                    <input
                      type="text"
                      v-model="item.api_questoes_area"
                      :class="{ 'is-invalid': $v.item.api_questoes_area.$error }"
                      class="form-control"
                    />
                  </div>
                </b-col>

                <div class="col-md-12 col-12">
                  <div class="form-group mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Edital
                    </label>
                    <input
                      type="text"
                      v-model="item.api_questoes_edital"
                      :class="{ 'is-invalid': $v.item.api_questoes_edital.$error }"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Detalhes">
            <b-card-text>
              <div class="row">
                <b-col md="12">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Tempo de acesso <small>(dias) </small>
                    </label>
                    <input
                      v-mask="['####']"
                      type="tel"
                      min="1"
                      v-model="item.validity"
                      :class="{ 'is-invalid': $v.item.validity.$error }"
                      placeholder="365"
                      class="form-control"
                    />
                  </div>
                </b-col>

                <div class="col-md-12 col-12">
                  <div class="form-group mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Sales force
                    </label>
                    <v-select
                      ref="selectSF"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.salesforce"
                      placeholder="Digite o título"
                      :options="optionsSalesForce"
                      @search="fetchSalesForce"
                      :class="{ 'is-invalid': $v.item.salesforce.$error }"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectSF.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Preço">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Preço
                    </label>
                    <money
                      class="form-control"
                      v-model="item.price"
                      placeholder="0,00"
                      v-bind="money"
                      :class="{ 'is-invalid': $v.item.price.$error }"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label for=""> Promocional </label>
                    <money
                      class="form-control"
                      v-model="item.sale"
                      placeholder="0,00"
                      v-bind="money"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <label for=""> Promoção válida até </label>
                    <flat-pickr
                      v-model="item.promotion_date"
                      class="form-control"
                      :config="{
                        dateFormat: 'd/m/Y',
                        altFormat: 'Y-m-d',
                        locale: 'pt',
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Opcionais">
            <b-card-text>
              <b-row class="form-row">
                <div class="col-12 mb-1">
                  <div class="form-group">
                    <label for=""
                      >Depoimento(s) <small>(Aprovados)</small>
                    </label>
                    <v-select
                      multiple
                      ref="selectApproved"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.approveds"
                      placeholder="Digite o título"
                      :options="optionsApproveds"
                      @search="fetchApproveds"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectApproved.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_store_discount"
                      value="1"
                      class="custom-control-primary"
                    >
                      Não aplicar desconto em loja
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_billet"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desabilitar venda no boleto
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_popup"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar Pop-up de interessado
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_form_contact"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar formulário de contato
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_whatsapp"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar botão do WhatsApp
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-form-checkbox
                      v-model="item.plataforma_questoes"
                      value="1"
                      class="custom-control-primary"
                    >
                      Acesso a Plataf. de Questões  
                    </b-form-checkbox>
                  </b-form-group>                  
                </div>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Tags">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <b-form-tags
                      separator=",;"
                      no-add-on-enter
                      class="form-control"
                      placeholder=""
                      v-model="item.tags"
                      :class="{ 'is-invalid': $v.item.tags.$error }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="mb-0 mt-2">Adicione tags separando-as por vírgula.</p>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Status">
            <b-card-text>
              <b-form-group class="mb-0">
                <v-select
                  label="title"
                  v-model="item.status"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                  :options="[
                    { title: 'Ativo', code: 1 },
                    { title: 'Inativo', code: 0 },
                  ]"
                />
              </b-form-group>
            </b-card-text>
          </b-card>
          <ButtonsActionsFooter
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
          <p class="mt-2"><i class="bi bi-plug"></i> ID Vindi: {{ item.external_id }}</p>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import draggable from "vuedraggable";
import { mapState } from "vuex";
import { stringToSlug } from "@/_helpers/_urlHelper";
import {
  BFormTags,
  BFormGroup,
  BForm,
  BRow,
  BImg,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BAvatar,
  BEmbed,
  BButton,
  BModal,
  BCardFooter,
  BSpinner,
  BListGroup,
  BBadge,
  BListGroupItem,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import { Money } from "v-money";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import vSelect from "vue-select";
import "swiper/css/swiper.css";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import { required } from "vuelidate/lib/validators";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    CustomPaginateTable,
    flatPickr,
    BInputGroup,
    BCard,
    Swiper,
    SwiperSlide,
    BSpinner,
    Money,
    editor: Editor,
    BBadge,
    BFormCheckbox,
    BModal,
    BImg,
    BEmbed,
    BCardText,
    draggable,
    BFormTags,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    ButtonsActionsFooter,
  },
  data() {
    return {
      dataItems: [],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      rowsTable: 0,
      submited: false,
      submitedFind: false,
      preview: null,
      image_url: "",
      user: {},
      wasUploads: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      item: {
        title: "",
        label_image: "",
        description: "",
        about: "",
        validity: 365,
        slug: "",
        price: "",
        sale: "",
        title_seo: "",
        description_seo: "",
        status: { title: "Ativo", code: 1 },
        api_questoes_edital: "",
        api_questoes_area: "",
        salesforce: "",
        tags: [],
        approveds: "",
        disable_store_discount: false,
        disable_billet: false,
        disable_popup: false,
        disable_form_contact: false,
        disable_whatsapp: false,
        product_category_uuid: "",
      },
      optionsSalesForce: [],
      optionsApproveds: [],
      uuid: "",
    };
  },
  created() {
    this.uuid = this.$route.params.uuid;
    this.getData();
  },
  watch: {
    "item.slug": {
      handler() {
        this.item.slug = stringToSlug(this.item.slug);
      },
    },
  },
  computed: {
    ...mapState("Product", ["searchSubcategories"]),
    ...mapState("Approved", ["searchApproveds"]),
  },
  methods: {
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      this.$store.dispatch("Product/show", this.$route.params.uuid).then((data) => {
        this.item = data;
        this.item.disable_store_discount = this.item.disable_store_discount
          ? 1
          : 0;
        this.item.disable_billet = this.item.disable_billet ? 1 : 0;
        this.item.disable_popup = this.item.disable_popup ? 1 : 0;
        this.item.disable_form_contact = this.item.disable_form_contact ? 1 : 0;
        this.item.disable_whatsapp = this.item.disable_whatsapp ? 1 : 0;
        
        this.image_url = this.item.image_url;
        this.item.tags = this.item.tags;
      });
    },
    async fetchApproveds(term) {
      if (term.length > 1) {
        this.$store.dispatch("Approved/search", term).then(() => {
          this.optionsApproveds = this.searchApproveds;
        });
      }
    },
    async fetchSalesForce(term) {
      if (term.length > 2) {
        this.$store.dispatch("SalesForce/search", term).then((response) => {
          this.optionsSalesForce = response;
        });
      }
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    submitForm() {
      this.$v.$touch();
      this.submited = true;

      const formData = new FormData();
      formData.append("image", this.item.image);
      formData.append("title", this.item.title);
      formData.append("title_seo", this.item.title_seo);
      formData.append("validity", this.item.validity);
      formData.append("price", this.item.price);
      formData.append("sale", this.item.sale);
      formData.append("promotion_date", this.item.promotion_date ?? "");
      formData.append("id_old", this.item.id_old ?? '');
      formData.append("description", this.item.description);
      formData.append("about", this.item.about);
      formData.append("description_seo", this.item.description_seo);
      formData.append("label_image", this.item.label_image);
      formData.append("video_url", this.item.video_url ?? "");
      formData.append("api_questoes_edital", this.item.api_questoes_edital ?? "");
      formData.append("api_questoes_area", this.item.api_questoes_area ?? "");

      formData.append(
        "salesforce",
        this.item.salesforce ? this.item.salesforce.code : ""
      );
      formData.append(
        "approveds",
        this.item.approveds ? JSON.stringify(this.item.approveds) : []
      );

      formData.append(
        "disable_store_discount",
        this.item.disable_store_discount ? 1 : 0
      );
      formData.append("disable_billet", this.item.disable_billet ? 1 : 0);
      formData.append("disable_popup", this.item.disable_popup ? 1 : 0);
      formData.append(
        "disable_form_contact",
        this.item.disable_form_contact ? 1 : 0
      );
      formData.append("disable_whatsapp", this.item.disable_whatsapp ? 1 : 0);
      formData.append(
        "tags",
        this.item.tags ? JSON.stringify(this.item.tags) : ""
      );
      formData.append("status", this.item.status ? this.item.status.code : "");
      formData.append("slug", this.item.slug);

      if (!this.$v.$error) {
        this.$store
          .dispatch("Product/update", { uuid: this.uuid, data: formData })
          .then(() => {
            this.$router.push({ name: "products-questions-list" });
            this.notifyDefault("success");
          })
          .catch(() => {
            this.submited = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
  },
  validations: {
    item: {
      title: {
        required,
      },
      price: {
        required,
      },
      label_image: {
        required,
      },
      validity: {
        required,
      },
      salesforce: {
        required,
      },
      api_questoes_edital: {
        required,
      },
      api_questoes_area: {
        required,
      },
      tags: {
        required,
      },
      status: {
        required,
      },
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    this.$root.$on('paginate:update', (res) => {
      this.currentPage = res._currentPage
      this.totalRows = res._totalRows
      this.rowsTable = res._rowsTable
      this.perPage = res._perPage
    });
  },
  beforeDestroy() {
    this.$root.$off('paginate:update');
  },
};
</script>

<style>
.custom-checkbox-footer label {
  font-size: 0.9rem !important;
}
.container-uploads svg {
  width: 70px;
}
.b-form-tags.form-control {
  padding: 1rem 1rem !important;
}
.beforeUpload .icon {
  width: 50px !important;
}
.area-lessons {
  min-height: 400px;
}
</style>